import { createStore } from 'vuex'

export default createStore({
  state: {
    // 全局状态
  },
  getters: {
    // 全局getter
  },
  mutations: {
    // 全局mutations
  },
  actions: {
    // 全局actions
  },
  modules: {
    // 模块化store
  }
}) 