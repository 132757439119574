import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/index.vue'
import RouteView from '../layout/RouteView.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/index.vue'),
        meta: {
          title: '仪表盘',
          requiresAuth: true
        }
      },
      {
        path: 'wechat',
        name: 'Wechat',
        component: RouteView,
        meta: {
          title: '微信管理',
          requiresAuth: true
        },
        children: [
          {
            path: 'config',
            name: 'WechatConfig',
            component: () => import('../views/wechat/config/index.vue'),
            meta: {
              title: '配置管理',
              requiresAuth: true
            }
          },
          {
            path: 'menu',
            name: 'WechatMenu',
            component: () => import('../views/wechat/menu/index.vue'),
            meta: {
              title: '菜单管理',
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: 'ai',
        name: 'AI',
        component: RouteView,
        meta: {
          title: 'AI管理',
          requiresAuth: true
        },
        children: [
          {
            path: 'config',
            name: 'AIConfig',
            component: () => import('../views/ai/config/index.vue'),
            meta: {
              title: 'AI配置',
              requiresAuth: true
            }
          },
          {
            path: 'chat',
            name: 'ChatHistory',
            component: () => import('../views/ai/chat/index.vue'),
            meta: {
              title: '对话历史',
              requiresAuth: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/error/403.vue'),
    meta: {
      title: '无权限访问'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/error/404.vue'),
    meta: {
      title: '页面不存在'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

// 添加路由守卫
router.beforeEach((to, from, next) => {
  console.log('路由跳转:', {
    from: from.path,
    to: to.path,
    toName: to.name
  })

  // 设置页面标题
  document.title = to.meta.title ? `${to.meta.title} - 微信公众号管理系统` : '微信公众号管理系统'

  // 检查是否需要登录
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token')
    if (!token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
      return
    }
  }

  next()
})

export default router 